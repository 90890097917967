import { enableProdMode, APP_INITIALIZER, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { getProductionEnabled, getBasePath } from './config';
import { AppComponent } from './app/app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { LottieModule } from 'ngx-lottie';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { FilterInterceptor } from './app/interceptors/filter.interceptor';
import { AuthInterceptor } from './app/interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { Overlay } from '@angular/cdk/overlay';
import { MAT_DATEPICKER_SCROLL_STRATEGY } from '@angular/material/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { customIconsInitializeFactory } from './app/utils';
import { CustomIconsService } from './app/services/custom-icons.service';
import { NbIconLibraries, NbThemeModule, NbLayoutModule, NbSidebarModule, NbMenuModule, NbDialogModule, NbDatepickerModule, NbTimepickerModule, NbToastrModule, NbCardModule } from '@nebular/theme';
import { BASE_PATH, ApiModule } from './api-new';
import player from 'lottie-web/build/player/lottie_svg';
import {provideRouter} from "@angular/router";
import {routes} from "./app/routes";
import {EmployerFilterService} from "./app/services/employer-filter.service";
import '@angular/common/locales/global/sk';

if (getProductionEnabled()) {
  enableProdMode();
}

export function playerFactory() {
  return player;
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/lang_', '.json');
}

export function employerFilterInit(employerFilterService: EmployerFilterService) {
  return (): Promise<void> | void => employerFilterService.reload();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    { provide: LOCALE_ID, useValue: 'sk' },
   importProvidersFrom(
    TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      NbThemeModule.forRoot({ name: 'corporate' }),
      NbLayoutModule,
      NbEvaIconsModule,
      NbSidebarModule.forRoot(),
      NbMenuModule.forRoot(),
      NbDialogModule.forRoot(),
      NbDatepickerModule.forRoot(),
      NbTimepickerModule.forRoot(),
      NbToastrModule.forRoot(),
      NbDateFnsDateModule.forRoot({ format: 'dd.MM.yyyy' }),
      ApiModule,
      LottieModule.forRoot({ player: playerFactory }),
      NbCardModule,
      MatNativeDateModule,
      MatInputModule,
      MatAutocompleteModule,
      MatSelectModule,
      MatMenuModule,
      MatButtonModule,
      MatIconModule,
      MatDialogModule
    ),
    {
      provide: BASE_PATH,
      useValue: getBasePath()
    },
    {
      provide: APP_INITIALIZER,
      useFactory: employerFilterInit,
      deps: [EmployerFilterService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [NbIconLibraries, CustomIconsService],
      useFactory: customIconsInitializeFactory
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    {
      provide: MAT_DATEPICKER_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.block(),
      deps: [Overlay]
    },
    { provide: LOCALE_ID, useValue: 'sk' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FilterInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
  .catch(err => console.error(err));
