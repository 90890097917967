import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    this.translate.addLangs(['sk', 'de']);
    this.translate.setDefaultLang('sk');
    this.translate.use('sk');

  }
}
