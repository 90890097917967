import { Routes } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';
import { NotLoggedInGuard } from './guards/not-logged-in.guard';

export const routes: Routes = [
  {
    path: 'prihlasenie',
    canActivate: [NotLoggedInGuard],
    loadChildren: () => import('../app/modules/auth/routes').then(m => m.AUTH_ROUTES)
  },
  {
    path: '',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('../app/modules/interface/routes').then(m => m.INTERFACE_ROUTES)
  },
  {
    path: 'zabudnute-heslo',
    loadChildren: () => import('../app/modules/forgotten-password/routes').then(m => m.FORGOTTEN_PASSWORD_ROUTES)
  },
  {
    path: 'reset-hesla',
    loadChildren: () => import('../app/modules/reset-password/routes').then(m => m.RESET_PASSWORD_ROUTES)
  },
  {
    path: 'accept-invitation',
    loadChildren: () => import('../app/modules/accept-invitation/routes').then(m => m.ACCEPT_INVITATION_ROUTES)
  }
];
